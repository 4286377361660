@import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap'); /*Prosto One*/
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap'); /*Days One*/
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /*Lobster*/
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap'); /*Andika*/
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap'); /*Dosis*/


/* Media query for laptop/desktop screens */
@media (min-width: 781px) {

/*<button class="button1001">COPY ME</button>*/
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap'); /*Days One*/
.button1001{
    width: 150px;
    height: 55px;
    font-size: 20px;
    background-color: red;
    color: white;
    font-size: 25px;
    font-family: "Days One", sans-serif;
    border-radius: 7px;
    padding: 5px;
    user-select: none;
    cursor: pointer;
    transition-duration: 0.3s;
}
.button1001:hover{
    background-color: rgb(171, 3, 3);
}
.button1001:active {
    box-shadow: 0 3px #666;
    transform: translateY(2px);
}

/*<button class="button2002">COPY ME</button>*/
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /*Lobster*/
.button2002 {
    width: 160px;
    height: 60px;
    color: black;
	background-color: inherit;
    padding: 15px 30px;
    user-select: none;
    border: none;
    border-radius:10px;
    cursor: pointer;
    font-size: 22px;
    letter-spacing: 2px;
    font-family: 'Lobster', cursive;
    transition-duration: 0.3s;
}
.button2002:hover {
	background-color: #eee;
}
.button2002:active {
    box-shadow: 0 3px #666;
    transform: translateY(3px);
}

/*<button class="button3003">COPY ME</button>*/
@import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap'); /*Prosto One*/
.button3003 {
    width: 160px;
    height: 60px;
	background-color: #eee;
    padding: 10px 18px;
    user-select: none;
    border: none;
    border-radius:10px;
    cursor: pointer;
    font-size: 22px;
    font-family: 'Prosto One', cursive;
    transition-duration: 0.3s;
}
.button3003:hover {
	background-color: #353935;
    color: white
}
.button3003:active {
    box-shadow: 0 3px #666;
    transform: translateY(2px);
}

/*<button class="button4004">COPY ME</button>*/
.button4004 {
    width: 140px;
    height: 60px;
    font-size: 20px;
    background-color: white;
    border-width: 10px;
    border-style: solid;
    font-family: 'Dosis', sans-serif;
    border-color: blue blue orange orange;
    cursor: pointer;
    transition-duration: 0.4s;
}
.button4004:hover{
	border-color: orange orange blue blue;
    border-width: 13px;
}
.button4004:active {
    box-shadow: 0 5px #666;
  	transform: translateY(4px);
}

/*<button class="button5005">COPY ME</button>*/
.button5005 {
    width: 140px;
    height: 60px;
    background-color: black;
    color: white;
    border-width: 10px;
    border-style: solid;
    font-family: 'Andika', sans-serif;
    border-color: red blue green yellow;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    transition-duration: 0.4s;
}
.button5005:hover{
	border-color: yellow red blue green;
    border-width: 8px;
}
.button5005:active {
    box-shadow: 0 5px #666;
  	transform: translateY(4px);
}


.button6006 {
    height: 40px;
    width: 140px;
    font-size: 20px;
    background-color: #242329;
    color: #fcfcfc;
    border: 2px whitesmoke solid;
    border-radius: 4px; 
    font-family: 'Dosis', sans-serif;
    transition-duration: 0.4s;
}
.button6006:hover {
    font-weight: bolder;
    cursor: pointer;
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.button7007 {
    height: 40px;
    width: 140px;
    font-size: 20px;
    font-family: 'Dosis', sans-serif;
    background-color: aquamarine;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;
}
    .button7007:hover {
    background-color: lightseagreen;
    font-weight: bolder;
}
.button7007:active {
    box-shadow: 0 2px #666;
    transform: translateY(2px);
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}



.allButtonsArea button {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}


.input1 {
    height: 27px;
    width: 130px;
    margin-left: 12px;
    border-radius: 5px;
  }
  .input1:focus {
    background-color: lightsalmon;
    color:black;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  .button8008{
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    will-change: box-shadow,transform;
    background: #FCFCFD;
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
    height: 33px;
    padding: 0 28px;
    font-size: 16px;
    border-radius: 6px;
    color: #36395a;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
  }
  
  .button8008:hover {
      box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
      transform: translateY(-2px);
  }
  .button8008:active{
      box-shadow: inset 0px 3px 7px #d6d6e7;
      transform: translateY(2px);
  }

  
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
.button1010 {
  font-family: 'Kanit', sans-serif;
  background-color: #101820;
  border: 2px solid #FEE715;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}
.button1010:hover {
  background-color: #0D141E;
  border-color: #FEE715;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: glowing 1.5s infinite;
}
.button1010:active {
  transform: scale(0.95);
}
.button1010:focus {
  outline: none;
  box-shadow: 0 0 5px #FEE715, 0 0 10px #FEE715;
}
@keyframes glowing {
  0% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
  50% { box-shadow: 0 0 20px rgba(254, 231, 21, 0.8); }
  100% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
}




    @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
    .button1010 {
      font-family: 'Kanit', sans-serif;
      background-color: #101820;
      border: 2px solid #FEE715;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    }
    .button1010:hover {
      background-color: #0D141E;
      border-color: #FEE715;
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      animation: glowing 1.5s infinite;
    }
    .button1010:active {
      transform: scale(0.95);
    }
    .button1010:focus {
      outline: none;
      box-shadow: 0 0 5px #FEE715, 0 0 10px #FEE715;
    }
    @keyframes glowing {
      0% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
      50% { box-shadow: 0 0 20px rgba(254, 231, 21, 0.8); }
      100% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
    } 
    

  .button1111 {
    font-family: "Lobster";
    font-size: 20px;
    width: 130px;
    height: 40px;
    background: #4822cc;
    border-radius: 4px;
    color: white;
    user-select: none;
    cursor: pointer;
  }
  .button1111 {
      margin-left: 12px;
  }
  .button1111:hover {
    background-color: #0080ff;
  }


  .buttonForInput{
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    will-change: box-shadow,transform;
    background: #FCFCFD;
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
    height: 33px;
    padding: 0 28px;
    font-size: 16px;
    border-radius: 6px;
    color: #36395a;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
  }
  
  .buttonForInput:hover {
      box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
      transform: translateY(-2px);
  }
  .buttonForInput:active{
      box-shadow: inset 0px 3px 7px #d6d6e7;
      transform: translateY(2px);
  }

}

/* Media query for mobile screens */
@media (max-width: 780px) {

      /*<button class="button1001">COPY ME</button>*/
      @import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap'); /*Days One*/
      .button1001{
          width: 150px;
          height: 55px;
          font-size: 20px;
          background-color: red;
          color: white;
          font-size: 25px;
          font-family: "Days One", sans-serif;
          border-radius: 7px;
          padding: 5px;
          user-select: none;
          cursor: pointer;
          transition-duration: 0.3s;
      }
      .button1001:hover{
          background-color: rgb(171, 3, 3);
      }
      .button1001:active {
          box-shadow: 0 3px #666;
          transform: translateY(2px);
      }

      /*<button class="button2002">COPY ME</button>*/
      @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /*Lobster*/
      .button2002 {
          width: 160px;
          height: 60px;
          color: black;
        background-color: inherit;
          padding: 15px 30px;
          user-select: none;
          border: none;
          border-radius:10px;
          cursor: pointer;
          font-size: 22px;
          letter-spacing: 2px;
          font-family: 'Lobster', cursive;
          transition-duration: 0.3s;
      }
      .button2002:hover {
        background-color: #eee;
      }
      .button2002:active {
          box-shadow: 0 3px #666;
          transform: translateY(3px);
      }

      /*<button class="button3003">COPY ME</button>*/
      @import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap'); /*Prosto One*/
      .button3003 {
          width: 160px;
          height: 60px;
        background-color: #eee;
          padding: 10px 18px;
          user-select: none;
          border: none;
          border-radius:10px;
          cursor: pointer;
          font-size: 22px;
          font-family: 'Prosto One', cursive;
          transition-duration: 0.3s;
      }
      .button3003:hover {
        background-color: #353935;
          color: white
      }
      .button3003:active {
          box-shadow: 0 3px #666;
          transform: translateY(2px);
      }

      /*<button class="button4004">COPY ME</button>*/
      .button4004 {
          width: 140px;
          height: 60px;
          font-size: 20px;
          background-color: white;
          border-width: 10px;
          border-style: solid;
          font-family: 'Dosis', sans-serif;
          border-color: blue blue orange orange;
          cursor: pointer;
          transition-duration: 0.4s;
      }
      .button4004:hover{
        border-color: orange orange blue blue;
          border-width: 13px;
      }
      .button4004:active {
          box-shadow: 0 5px #666;
          transform: translateY(4px);
      }

      /*<button class="button5005">COPY ME</button>*/
      .button5005 {
          width: 140px;
          height: 60px;
          background-color: black;
          color: white;
          border-width: 10px;
          border-style: solid;
          font-family: 'Andika', sans-serif;
          border-color: red blue green yellow;
          font-weight: bold;
          font-size: 20px;
          cursor: pointer;
          transition-duration: 0.4s;
      }
      .button5005:hover{
        border-color: yellow red blue green;
          border-width: 8px;
      }
      .button5005:active {
          box-shadow: 0 5px #666;
          transform: translateY(4px);
      }


      .button6006 {
          height: 40px;
          width: 140px;
          font-size: 20px;
          background-color: #242329;
          color: #fcfcfc;
          border: 2px whitesmoke solid;
          border-radius: 4px; 
          font-family: 'Dosis', sans-serif;
          transition-duration: 0.4s;
      }
      .button6006:hover {
          font-weight: bolder;
          cursor: pointer;
          box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
      }

      .button7007 {
          height: 40px;
          width: 140px;
          font-size: 20px;
          font-family: 'Dosis', sans-serif;
          background-color: aquamarine;
          padding: 4px;
          border-radius: 5px;
          cursor: pointer;
          transition-duration: 0.3s;
      }
          .button7007:hover {
          background-color: lightseagreen;
          font-weight: bolder;
      }
      .button7007:active {
          box-shadow: 0 2px #666;
          transform: translateY(2px);
          box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
      }

      .allButtonsArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }


      .allButtonsArea button {
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 20px;
      }


      .input1 {
          height: 27px;
          width: 130px;
          margin-left: 12px;
          border-radius: 5px;
        }
        .input1:focus {
          background-color: lightsalmon;
          color:black;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }


        .button8008{
          display: inline-block;
          outline: 0;
          border: 0;
          cursor: pointer;
          transition: box-shadow 0.15s ease,transform 0.15s ease;
          will-change: box-shadow,transform;
          background: #FCFCFD;
          box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
          height: 33px;
          padding: 0 28px;
          font-size: 16px;
          border-radius: 6px;
          color: #36395a;
          transition: box-shadow 0.15s ease,transform 0.15s ease;
        }
        
        .button8008:hover {
            box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
            transform: translateY(-2px);
        }
        .button8008:active{
            box-shadow: inset 0px 3px 7px #d6d6e7;
            transform: translateY(2px);
        }

        
      @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
      .button1010 {
        font-family: 'Kanit', sans-serif;
        background-color: #101820;
        border: 2px solid #FEE715;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
      }
      .button1010:hover {
        background-color: #0D141E;
        border-color: #FEE715;
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        animation: glowing 1.5s infinite;
      }
      .button1010:active {
        transform: scale(0.95);
      }
      .button1010:focus {
        outline: none;
        box-shadow: 0 0 5px #FEE715, 0 0 10px #FEE715;
      }
      @keyframes glowing {
        0% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
        50% { box-shadow: 0 0 20px rgba(254, 231, 21, 0.8); }
        100% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
      }




          @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
          .button1010 {
            font-family: 'Kanit', sans-serif;
            background-color: #101820;
            border: 2px solid #FEE715;
            color: white;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
          }
          .button1010:hover {
            background-color: #0D141E;
            border-color: #FEE715;
            transform: scale(1.05);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            animation: glowing 1.5s infinite;
          }
          .button1010:active {
            transform: scale(0.95);
          }
          .button1010:focus {
            outline: none;
            box-shadow: 0 0 5px #FEE715, 0 0 10px #FEE715;
          }
          @keyframes glowing {
            0% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
            50% { box-shadow: 0 0 20px rgba(254, 231, 21, 0.8); }
            100% { box-shadow: 0 0 5px rgba(254, 231, 21, 0.8); }
          } 
          

        .button1111 {
          font-family: "Lobster";
          font-size: 20px;
          width: 130px;
          height: 40px;
          background: #4822cc;
          border-radius: 4px;
          color: white;
          user-select: none;
          cursor: pointer;
        }
        .button1111 {
            margin-left: 12px;
        }
        .button1111:hover {
          background-color: #0080ff;
        }

        .buttonForInput{
          display: inline-block;
          width: 140px;
          font-size: 18px;
          outline: 0;
          border: 0;
          transition: box-shadow 0.15s ease,transform 0.15s ease;
          will-change: box-shadow,transform;
          background: #FCFCFD;
          box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
          height: 33px;
          padding: 0 10px;
          font-size: 16px;
          border-radius: 6px;
          color: #36395a;
          transition: box-shadow 0.15s ease,transform 0.15s ease;
        }
        .buttonForInput:active{
            box-shadow: inset 0px 3px 7px #d6d6e7;
            transform: translateY(2px);
        }
}