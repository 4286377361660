
/* Media query for mobile screens */
@media (min-width: 781px) {
  .button_plus {
    position: relative; /* Change from absolute to relative */
    width: 40px;
    height: 35px !important;
    background: #fff;
    cursor: pointer;
    border: 3px solid #095776;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button_plus:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  
  
  .dateInputAreas {
    height: 30px;
    border-radius: 5px;
    border: 2px solid red;
    
  }
  
  
  /* Flexbox container for the inputs and button to align them horizontally */
  .flex-container {
    display: flex;
    gap: 15px; /* Space between inputs and button */
  }
  .inputAreaContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .date-input-wrapper {
    position: relative; /* Establishes a relative position so the icon can be absolutely positioned inside this container */
    display: inline-block; /* Ensures the wrapper behaves like an inline element and doesn't take up the full width */
  }
  /* Positioning the custom calendar icon inside the input */
  .custom-calendar-icon {
    position: absolute; /* Positions the icon absolutely within the .date-input-wrapper */
    right: 4px; /* Aligns the icon 10px from the right edge of the input */
    top: 50%; /* Aligns the icon vertically by placing it halfway down the input */
    transform: translateY(-50%); /* Corrects vertical alignment by pulling the icon up by 50% of its height, centering it vertically */
    pointer-events: none; /* Ensures the icon doesn't interfere with input field interactions (e.g., clicking or focusing) */
    width: 25px; /* Sets the width of the icon to 20px */
    height: 25px; /* Sets the height of the icon to 20px */
  }
  
  
  .resultText1{
    font-size: 17px;
  }
  
  ul {
    list-style: none; /* Remove default bullets */
  }
  
  ul li::before {
    content: "\2022"; /* Unicode for a small bullet • */
    color: #666; /* Make the bullet a light gray color */
    font-size: 13px; /* Reduce the bullet size */
    margin-right: 10px; /* Add some space between the bullet and the text */
  }
  
  .arrowIcon {
    font-size: 20px; /* Increase the size of the arrow */
    color: #333; /* Set a subtle dark color */
    margin: 0 10px; /* Add space around the arrow */
    transform: translateY(1px); /* Slightly adjust vertical alignment */
  }
}

/* Media query for mobile screens */
@media (max-width: 780px) {
  .button_plus {
    position: relative; /* Change from absolute to relative */
    width: 40px;
    height: 35px !important;
    background: #fff;
    cursor: pointer;
    border: 3px solid #095776;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button_plus:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  
  .dateInputAreas {
    height: 30px;
    border-radius: 5px;
    border: 2px solid red;
    
  }
  /* Flexbox container for the inputs and button to align them horizontally */
  .flex-container {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between inputs and button */
  }
  .inputAreaContainer2 {
    display: flex;
    flex-direction: column;
  }
  
  .date-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    position: relative;
  }
  /* Positioning the custom calendar icon inside the input */
  .custom-calendar-icon {
    position: absolute; /* Positions the icon absolutely within the .date-input-wrapper */
    top: 3px;             /* Align to the top of the container */
    right: 5px;           /* Align to the right side of the container */
    pointer-events: none; /* Ensures the icon doesn't interfere with input field interactions */
    width: 25px;        /* Sets the width of the icon to 25px */
    height: 25px;       /* Sets the height of the icon to 25px */
  }
  .resultText1{
    font-size: 17px;
  }
  .resultSchengenCalculator {
    padding-right: 12px;
  }
  ul {
    list-style: none; /* Remove default bullets */
    padding: 0;       /* Remove default padding */
    margin: 0;        /* Remove default margin */
    margin-top: 10px; /*Add a little top margin */
  }
  ul li::before {
    content: "\2022"; /* Unicode for a small bullet • */
    color: #666; /* Make the bullet a light gray color */
    font-size: 13px; /* Reduce the bullet size */
    margin-right: 10px; /* Add some space between the bullet and the text */
  }
  
  .arrowIcon {
    font-size: 20px; /* Increase the size of the arrow */
    color: #333; /* Set a subtle dark color */
    margin: 0 10px; /* Add space around the arrow */
    transform: translateY(1px); /* Slightly adjust vertical alignment */
  }
}