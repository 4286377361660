/* Media query for mobile screens */
@media (min-width: 781px) {
    .comment-container {
      max-width: 600px;
      margin-left: 12px;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #f1ec81;
      margin-bottom: 20px;
    }

    .comment-form {
      display: flex;
      flex-direction: column;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      margin-bottom: 5px;
      font-weight: bold;
      /*font-family: "Playwrite HU";*/
      font-family: "Ubuntu";
    }

    .form-group input,
    .form-group textarea {
      width: calc(100% - 20px); /* Adjust width to account for padding */
      padding: 10px;
      margin: 0; /* Remove default margin */
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
      font-family: "Poppins";
    }

    .form-group textarea {
      resize: vertical;
      min-height: 100px;
    }

    .comment-container button[type='submit'] {
      width: 20%;
      padding: 5px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Ubuntu";
      font-size: 22px;
    }

    .comment-container button[type='submit']:hover {
      background-color: #218838;
    }

    .commentReplyFormContainer {
      width: 450px;
      margin-top: 5px;
      padding: 5px;

      border-radius: 4px;
      background-color: #f1ec81;
      margin-bottom: 20px;
    }
    .commentReplyFormContainer button {
      width: 100px;
      padding: 4px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Ubuntu";
      font-size: 19px;
    }
    .commentReplyFormContainer button:hover {
      background-color: #218838;
    }
    .commentReplyForm {
      display: flex;
      flex-direction: column;
    }
    .commentReplyFormParts {
      margin-top: 10px;
    }
    .commentReplyFormParts input,
    .commentReplyFormParts textarea {
      width: 440px; /* Adjust width to account for padding */
      padding: 10px;
      margin: 0; /* Remove default margin */
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
      font-family: "Poppins";
    }
    .commentReplyFormParts textarea {
      resize: vertical;
      min-height: 100px;
    }
    .commentReplyFormButtonsDiv {
      display: flex;
      flex-direction: row;

    }
    .commentReplyFormButtonsDiv button{
      margin-right: 20px;
    }
}
/* Media query for mobile screens */
@media (max-width: 780px) {
    .comment-container {
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #f1ec81;

    }
    .comment-form {
      display: flex;
      flex-direction: column;
    }
    .form-group {
      margin-bottom: 10px;
    }
    .form-group label {
      margin-bottom: 5px;
      font-weight: bold;
      /*font-family: "Playwrite HU";*/
      font-family: "Ubuntu";
    }
    .form-group input,
    .form-group textarea {
      width: 95%; /* Adjust width to account for padding */
      padding: 10px;
      margin: 0; /* Remove default margin */
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
      font-family: "Poppins";
      font-size: 16px;
    }
    .form-group textarea {
      resize: vertical;
      min-height: 120px;
    }
    .comment-container button[type='submit'] {
      width: 80px;
      padding: 5px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Ubuntu";
      font-size: 22px;
    }
    .commentReplyFormContainer {
      width: 86%;
      margin-top: 10px;
      padding: 10px;
      border-radius: 4px;
      background-color: #f1ec81;
      margin-bottom: 10px;
    }
    .commentReplyFormContainer button {
      width: 100px;
      height: 36px;
      padding: 4px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Ubuntu";
      font-size: 22px;
    }
    .commentReplyForm {
      display: flex;
      flex-direction: column;
    }
    .commentReplyFormParts {
      margin-top: 7px;
      margin-bottom: 10px;
    }
    .commentReplyFormParts input,
    .commentReplyFormParts textarea {
      width: 98%; /* Adjust width to account for padding */
      padding: 10px;
      margin: 0; /* Remove default margin */
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
      font-family: "Poppins";
      font-size: 16px;
    }
    .commentReplyFormParts textarea {
      resize: vertical;
      min-height: 100px;
    }
    .commentReplyFormButtonsDiv {
      display: flex;
      flex-direction: row;
    }
    .commentReplyFormButtonsDiv button{
      margin-right: 20px;
    }
  }