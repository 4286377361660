@media (min-width: 781px) {
  /* styles/Footer.css */
  .footer {
    padding: 1rem 2rem; /* Adjusted padding for more balanced spacing */
    display: inline-block;
    border: 3px solid #333333;
    font-size: 1.2rem;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    background: #ffffff;
    position: relative;
    margin-top: 2rem;
    background-color: teal;
    font-family: "Josefin Sans";
    font-size: 16px;
    width: 70%; /* Increased width for a wider footer box */
    text-align: left;
    margin-left: 12px;
  }
  .footer::before {
    content: '';
    border: 2px solid #353535;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
  }
  .footer-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer p {
    margin: 0.5rem 0;
  }
  .footer a {
    color: #333333;
    text-decoration: none;
  }
  .home-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333333;
  }
}

/* Media query for mobile screens */
@media (max-width: 780px) {
    /* styles/Footer.css */
    .footer {
      display: inline-block;
      position: relative;
      min-width: 100%;
      max-width: 100%;
      margin-top: 10px;

      border: 3px solid #333333;
      border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
      background: #ffffff;
      background-color: teal;
      font-family: "Josefin Sans";
      font-size: 16px;
      text-align: left;
    }

    .footer::before {
      content: '';
      border: 2px solid #353535;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
      border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
    }

    .footer-content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }


    .footer p {
      margin: 8px;
    }

    .footer a {
      color: #333333;
      text-decoration: none;
    }

    .home-icon {
      display: none;
/*       position: absolute;
      top: 5px;
      right: 5px;
      font-size: 1.5rem;
      color: #333333; */
    }
}
