@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+HU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Playwrite+HU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap'); /* Nerko One*/
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Lobster&family=Nerko+One&display=swap'); /*Acme Lobster Nerko One*/
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); /*Fredoka One*/
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap'); /*Courgette*/
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');/*Comfortaa*/
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Righteous&display=swap');/*Concert One*/
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');/*Righteous*/
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Monda:wght@700&family=Righteous&display=swap');/*Monda*/
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');/*Maven Pro*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap'); /*Nunito*/
/*Nunito Maven Pro Righteous Monda Concert One Courgette Fredoka One Lobster Nerko One Poppins Playwrite HU*/

@media (min-width: 781px) {
html {
    font-size: 1.25rem;
    font-family: "Ubuntu", sans-serif;
  }
  
  body {
    margin: 0;
    background-color: white;
    font-family: "Ubuntu", sans-serif;
  }
  .App {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
  }

  .sidebarArea {
    min-width: 20%;
    background-color: #CCECFE;
    color: black;
    /*
    background-color: #093945;
    color: white;
    */
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.3);/*border right shade*/
    height: 100vh;
    overflow-y: auto; /* Content scrolls if overflow */
    scrollbar-width: none; /* For Firefox */
    user-select: none;
  }

  /* For WebKit-based browsers (Chrome, Safari) */
  .sidebarArea::-webkit-scrollbar {
      display: none; /* Hide scrollbar */
  }
  
  .sidebarLogoArea {
    background-color: #CCECFE;
    min-width: 100%;
    /*placing logo to the center horizontally and vertically*/
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); /* Bottom border shadow   */
  }
  .sidebarButtonsArea { 
    cursor: pointer;
    /* background-image: linear-gradient(92.83deg, #3C6478 0,  #f25500  100%); */
    background-color: #00246B;
  }
  .sidebarMainButtons{
    box-shadow: 0px 0.4px 3px #b0b0b0; /* Bottom border shadow */
    color: #CADCFC;
    font-family: "Monda";
  }
  .sidebarMainButtons:hover {
    background-color: #3C6478;
    /* Light gray border on hover */
  }
  .sidebarMainButtons:active{
    background-color: #94b8c9;
  }

  .sidebarSideButtons {
    background-color: #EFD469;
    color: black;
    box-shadow: 0px 0.2px 2px #b0b0b0; /* Bottom border shadow */
    font-family: "Concert One", sans-serif; /* Example font family */
    font-size: 0.75rem;
    padding-left: 30px !important;
  }
  .sidebarSideButtons:hover {
    background-color: #bb9913;
  }
  .sidebarMainButtons, .sidebarSideButtons {
    padding: 15px 10px 5px 18px;/*top-right-bottom-left*/
  }


  .mainArea {
    min-width: 80%;
    height: 100vh;
    overflow-y: auto;
  }

  .notesArea {
    font-family: "Edu AU VIC WA NT Hand", cursive;
  }

  .homepageArea {
    margin-left: 12px;
    margin-top: 30px;
  }

  .customsNotesArea {
    margin-left: 12px;
  }

  .buttonsMainArea {
    margin-left: 12px;
  }

}
/* Media query for mobile screens */
@media (max-width: 780px) {
  html {
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
  }
  
  body {
    margin: 0;
    background-color: white;
    font-family: "Ubuntu", sans-serif;
  }
  .App {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 100%; /* Full viewport height */
  }

  .mainArea {
    min-width: 100%;
    padding-bottom: 25px;
  }

  .sidebarArea {
    min-width: 100%;
    background-color: #CCECFE;
    color: black;
    /*
    background-color: #093945;
    color: white;
    */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); /* Border bottom shadow */
    overflow-y: none;
     /* Content scrolls if overflow */
    scrollbar-width: auto; /* For Firefox */
    user-select: none;
    height: auto;
  }


  
  .sidebarLogoArea {
    background-color: #CCECFE;
    min-width: 100%;
    /*placing logo to the center horizontally and vertically*/
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4); /* Bottom border shadow   */
  }
  .sidebarButtonsArea { 
    cursor: pointer;
    /* background-image: linear-gradient(92.83deg, #3C6478 0,  #f25500  100%); */
    background-color: #00246B;
  }
  .sidebarMainButtons{
    box-shadow: 0px 0.4px 2px #b0b0b0; /* Bottom border shadow */
    color: #f3f3f3;
    font-family: "Monda";
  }

  .sidebarSideButtons {
    background-color: #EFD469;
    color: black;
    box-shadow: 0px 0.2px 2px #b0b0b0; /* Bottom border shadow */
    font-family: "Poppins", sans-serif; /* Nunito Maven Pro Righteous Monda Concert One Courgette Fredoka One Lobster Nerko One Poppins Playwrite HU*/
    font-size: 16px;
    padding-left: 25px !important;
  }
  .sidebarSideButtons:hover {
    background-color: #bb9913;
  }
  .sidebarMainButtons, .sidebarSideButtons {
    padding: 11px 8px 4px 13px;/*top-right-bottom-left*/
  }

  .notesArea {
    font-family: "Edu AU VIC WA NT Hand", cursive;
  }

  .homepageArea {
    margin-left: 12px;
    margin-top: 30px;
  }

  .customsNotesArea {
    padding-left: 20px;
    text-align: justify;
    padding-right: 20px;
  }
  .customsNotesAreaExtraMargin {
    margin-bottom: 10px; /* Adjust as needed */
    display: block; /* Make it behave like a block element to apply margin */
  }

  .buttonsMainArea {
    padding-left: 10px;
    text-align: justify;
    padding-right: 10px;
  }
}
