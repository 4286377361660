@import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Nerko+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Lobster&family=Nerko+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); /*Fredoka One*/
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap'); /*Courgette*/
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');/*Comfortaa*/
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Righteous&display=swap');/*Concert One*/
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');/*Righteous*/
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Monda:wght@700&family=Righteous&display=swap');/*Monda*/
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');/*Maven Pro*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap'); /*Nunito*/

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Nerko+One&display=swap');
@media (min-width: 871px) {
.customsMainArea {
    display: flex;
    flex-direction: column;
    font-family: "Maven Pro", 'Trebuchet MS', sans-serif;
}

@media (min-width: 871px) {
    .customsMainArea {
        margin-left: 20px;
    }
}

.customsMainArea h1, h2, h3 {
    font-family: "Comfortaa", 'Trebuchet MS', sans-serif;
}
.customsMainPageTitlesArea {
    display: flex;
    flex-direction: column; /* Stack elements vertically if needed */
    justify-content: center; /* Align the content to the center horizontally */
    align-items: center; /* Aligns items in the center of the flex container */
    width: 100%; /* Ensure the container takes the full width */
    text-align: center; /* Center the text itself */
    
}


.button102 {
    font-family: "Lobster";
    font-size: 20px;
    width: 20%;
    background: #4822cc;
    border-radius: 4px;
    color: white;
    user-select: none;
    cursor: pointer;
}
.button102 {
    margin-left: 12px;
}

.notesSpan {
    color: red;
    font-style: italic;
    font-weight: bold;
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
    /* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input2 {
    border: solid 1px blue;
    height: 20px;
    width: 130px;
    border-radius: 4px;
}
.input2:focus {
    background-color: lightsalmon;
    color:black;
    outline: none;
}

.customsTable {
    width: 35%;
    margin: 5px;
    border: 1px solid blue;
    border-radius: 5px;
    font-size: 15px;
    font-family: "Maven Pro";
  }
  .customsRow td {
    border: 1px solid blue;
  }
}
  /* Media query for mobile screens */
@media (max-width: 870px) {
    .customsMainArea {
        display: flex;
        flex-direction: column;
        font-family: "Maven Pro", 'Trebuchet MS', sans-serif;
    }
    .customsMainArea h1, h2, h3 {
        font-family: "Comfortaa", 'Trebuchet MS', sans-serif;
    }

    .customsTexts { /*some customs form contains a table and some dont. This targets the customs without table*/
        font-size: 18px;
    }
    .customsForm label { /*some customs form contains a table and some dont. This targets the customs with table*/
        font-size: 18px;
    }
    .customsLeftMargin {
        padding-left: 10px;
        padding-right: 10px;
    }
    .customsMainPageTitlesArea {
        display: flex;
        flex-direction: column; /* Stack elements vertically if needed */
        justify-content: center; /* Align the content to the center horizontally */
        align-items: center; /* Aligns items in the center of the flex container */
        width: 100%; /* Ensure the container takes the full width */
        text-align: center; /* Center the text itself */
    }
    
    .customsMainPageButtonsArea {
        width: 100%;
        padding-left: 20px;
    }
    
    .button102 {
        font-family: "Lobster";
        font-size: 20px;
        width: 40%;
        height: 40px;
        background: #4822cc;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        margin-bottom: 20px;
        margin-left: 12px;
    }
    
    .notesSpan {
        color: red;
        font-style: italic;
        font-weight: bold;
        cursor: pointer;
    }
    
    /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
        /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    
    .input2 {
        border: solid 1px blue;
        height: 30px;
        width: 130px;
        border-radius: 4px;
        font-size: 18px;
        margin-top: 5px;
    }
    .input2:focus {
        background-color: lightsalmon;
        color:black;
        outline: none;
    }
    
    .customsTable {
        width: 35%;
        margin: 5px;
        border: 1px solid blue;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Maven Pro";
      }
      .customsRow td {
        border: 1px solid blue;
      }
      .radioLabelsCustoms {
        margin-bottom: 10px;
      }
}