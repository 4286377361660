/* Media query for mobile screens */
@media (min-width: 781px) {
    .formHeader {
        margin-left: 12px;
    }
    .formInvestment button {
        margin-left: 12px;
    }
    .resultAreaInvestment {
        margin-top: 20px;
        margin-left: 12px;
    }
    /*<button class="button1">COPY ME</button>*/
    @import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
    .button101{
        width: 150px;
        height: 55px;
        font-size: 20px;
        background-color: red;
        color: white;
        font-size: 25px;
        font-family: "Days One", sans-serif;
        border-radius: 7px;
        padding: 5px;
        user-select: none;
        cursor: pointer;
        transition-duration: 0.3s;
    }
    .button101:hover{
        background-color: rgb(171, 3, 3);
    }
    .button101:active {
        box-shadow: 0 3px #666;
        transform: translateY(2px);
    }
    /*<input className="inputFields" />*/
    .inputFields {
        height: 27px;
        width: 130px;
        margin-left: 12px;
        border-radius: 5px;
    }
    .inputFields:focus {
        background-color: lightsalmon;
        color:black;
    }
    /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .resultAreaRelativisticMass {
        max-width:90%;
    }
    
}

/* Media query for mobile screens */
@media (max-width: 780px) {
    .formHeader {
        margin-left: 12px;
        font-size: 25px;
    }
    .formInvestment button {
        margin-left: 12px;
    }
    .resultAreaInvestment {
        margin-top: 20px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 18px;
    }
    /*<button class="button1">COPY ME</button>*/
    @import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
    .button101{
        width: 150px;
        height: 40px;
        font-size: 18px;
        background-color: red;
        color: white;
        font-size: 22px;
        font-family: "Days One", sans-serif;
        border-radius: 7px;
        padding: 5px;
        user-select: none;
        cursor: pointer;
        transition-duration: 0.3s;
    }
    .button101:active {
        box-shadow: 0 3px #666;
        transform: translateY(2px);
    }
    /*<input className="inputFields" />*/
    .inputFields {
        height: 27px;
        width: 130px;
        margin-left: 12px;
        border-radius: 5px;
        font-size: 20px;
    }
    .inputFields:focus {
        background-color: lightsalmon;
        color:black;
    }
    /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}